<template>
  <div>
    <b-card
      no-body
    >
      <b-card-header class="pb-50">
        <h5>
          Filtros
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Buscar por"
              label-for="palavraChave"
            >
              <b-form-input
                id="palavraChave"
                v-model="palavraChaveFilter"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label>Status</label>
            <v-select
              v-model="statusSessaoFilter"
              :options="statusSessaoOptions"
              :clearable="false"
            />
          </b-col>

          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Ordenar por"
              label-for="sortBySelect"
            >
              <b-input-group>
                <v-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-70"
                  :clearable="false"
                />
                <v-select
                  v-model="sortDesc"
                  :options="sortDescOptions"
                  :disabled="!sortBy"
                  class="w-30"
                  :clearable="false"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label style="visibility:hidden">Ação</label>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="form-control"
              @click.prevent="buscarFiltro"
            >
              Pesquisar
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <b-table
        ref="refListTable"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        striped
        style="min-height: 250px"
      >

        <template #cell(nome)="data">
          <span
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.left="data.value"
          >
            {{ data.value.split(' ')[0] }}
          </span>
        </template>

        <template #cell(status)="data">
          <b-badge :variant="getStatusStyle(data.value)">
            {{ data.value.split(',')[0] }}
          </b-badge>
          <b-badge
            v-if="data.value.split(',')[1]"
            :variant="getStatusStyle(data.value)"
          >
            {{ data.value.split(',')[1] }}
          </b-badge>
        </template>

        <template #cell(saldoInicio)="data">
          {{ toFixed(data.value, 2) }}
        </template>

        <template #cell(saldoAtual)="data">
          <template v-if="data.value">
            {{ toFixed(data.value, 2) }}
          </template>
        </template>

        <template #cell(lucro)="data">
          <template v-if="data.value && data.item.saldoAtual>0">
            <b-badge :variant="data.value>=0? 'light-success' : 'light-danger'">
              {{ toFixed(data.value, 2) }}%
            </b-badge>
          </template>
        </template>

        <template #cell(dataInicio)="data">
          {{ formatDateBR(data.value) }}
        </template>

        <template #cell(dataFim)="data">
          {{ formatDateBR(data.value) }}
        </template>

        <template #cell(dataAtualizacao)="data">
          <div class="text-nowrap">
            {{ formatDateTimeBR(data.value) }}
          </div>
        </template>

        <template #cell(dataPagamento)="data">
          {{ formatDateBR(data.value) }}
        </template>

        <template #cell(dataCadastro)="data">
          {{ formatDateBR(data.value) }}
        </template>

        <template #cell(obs)="data">
          <span
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.left="data.value"
          >
            {{ data.value.substr(0, 35) }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(acao)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :disabled="data.item.status != 'Pendente' && data.item.status != 'Pendente,Aguardando pagamento'"
              @click.stop="confirmarValidarPendencia(data.item.id)"
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Validar pendência</span>
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="data.item.status != 'Ativo'"
              @click.stop="confirmarFinalizar(data.item.id)"
            >
              <feather-icon icon="TargetIcon" />
              <span class="align-middle ml-50">Finalizar</span>
            </b-dropdown-item>
          </b-dropdown>

        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ paginationMeta.from }} a {{ paginationMeta.to }} de {{ paginationMeta.of }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BTable, BPagination, BRow, BCol, BBadge, BButton, VBTooltip, BFormGroup, BFormInput, BDropdown, BDropdownItem, BInputGroup,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import utilsMixin from '@/mixins/utilsMixin'
import { formatDateBR, formatDateTimeBR } from '@/utils/filter'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import sessaoUsuarioStoreModule from './sessaoUsuarioStoreModule'

const SESSAO_USUARIO_FUT_STORE_MODULE_NAME = 'sessao-usuario-fut'

export default {

  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BTable,
    BPagination,
    BRow,
    BCol,
    BBadge,
    BButton,
    vSelect,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BInputGroup,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      formatDateBR,
      formatDateTimeBR,
      tableColumns: [
        { key: 'id', label: 'ID' },
        { key: 'nome', label: 'Nome' },
        { key: 'email', label: 'Email' },
        { key: 'status', label: 'Status' },
        { key: 'plano', label: 'Plano' },
        { key: 'dataInicio', label: 'Início' },
        { key: 'saldoInicio', label: 'Saldo inicial' },
        { key: 'saldoAtual', label: 'Saldo atual' },
        { key: 'lucro', label: 'Lucro (%)' },
        { key: 'dataFim', label: 'Término' },
        { key: 'dataAtualizacao', label: 'Atualizado em' },
        { key: 'dataCadastro', label: 'Cadastrado em' },
        { key: 'obs', label: 'Observações' },
        { key: 'agente', label: 'Agente' },
        { key: 'acao', label: 'Ações' },
      ],
      perPage: 15,
      totalCount: 0,
      currentPage: 1,
      sortBy: { label: 'Cadastrado em', value: 'dataCadastro' },
      sortDesc: { label: 'Desc', value: 'desc' },
      dataList: [],
      statusSessaoOptions: [
        { label: 'Todos', value: null },
        { label: 'Pendente', value: '0' },
        { label: 'Aguardando pagamento', value: '1' },
        { label: 'Pendente, Aguardando pagamento', value: '4' },
        { label: 'Ativo', value: '2' },
        { label: 'Finalizado', value: '3' },
      ],
      sortDescOptions: [
        { label: 'Asc', value: 'asc' },
        { label: 'Desc', value: 'desc' },
      ],
      sortOptions: [
        { label: 'Cadastrado em', value: 'dataCadastro' },
        { label: 'Início', value: 'dataInicio' },
        { label: 'Término', value: 'dataFim' },
      ],

      statusSessaoFilter: { label: 'Todos', value: null },
      palavraChaveFilter: null,
    }
  },

  computed: {

    paginationMeta() {
      const localItemsCount = this.$refs.refListTable ? this.$refs.refListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalCount,
      }
    },
  },

  watch: {
    currentPage() {
      this.fetchList()
      this.$refs.refListTable.refresh()
    },
  },

  created() {
    if (!store.hasModule(SESSAO_USUARIO_FUT_STORE_MODULE_NAME)) store.registerModule(SESSAO_USUARIO_FUT_STORE_MODULE_NAME, sessaoUsuarioStoreModule)
    this.fetchList()
  },

  beforeDestroy() {
    if (store.hasModule(SESSAO_USUARIO_FUT_STORE_MODULE_NAME)) store.unregisterModule(SESSAO_USUARIO_FUT_STORE_MODULE_NAME)
  },

  methods: {

    confirmarValidarPendencia(id) {
      this.$swal({
        title: 'Tem certeza que deseja Validar as pendências da sessão?',
        text: 'Os erros atuais serão reverificados',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, pode verificar!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.veiricarPendencia(id)
        }
      })
    },

    // finalizar
    confirmarFinalizar(id) {
      this.$swal({
        title: 'Tem certeza que deseja finalizar a sessão?',
        text: 'Suas posições serão encerradas e o BOT desativado',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, pode finalizar!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.finalizar(id)
        }
      })
    },

    /* eslint no-else-return: "error" */
    getStatusStyle(status) {
      if (status === 'Ativo') {
        return 'light-success'
      } else if (status === 'Aguardando Pagamento') {
        return 'light-warning'
      } else if (status === 'Finalizado') {
        return 'light-secondary'
      }
      return 'light-primary'
    },

    buscarFiltro() {
      this.page = 1
      this.fetchList()
    },

    fetchList() {
      store
        .dispatch('sessao-usuario-fut/getSessaoUsuarios', {
          size: this.perPage,
          page: this.currentPage - 1,
          sort: `${this.sortBy.value},${this.sortDesc.value}`,
          status: this.statusSessaoFilter.value,
          chave: this.palavraChaveFilter,
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataList = content
          this.totalCount = totalElements
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao recuperar a lista.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    veiricarPendencia(id) {
      const formData = new FormData()
      formData.append('id', id)

      this.$store.dispatch('sessao-usuario-fut/verificarPendencia', formData)
        .then(() => {
          this.fetchList()
          this.$swal({
            icon: 'success',
            title: 'Sessão verificada!',
            text: 'A sessão foi verifica com sucesso',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },

    finalizar(id) {
      const formData = new FormData()
      formData.append('id', id)

      this.$store.dispatch('sessao-usuario-fut/finalizar', formData)
        .then(() => {
          this.fetchList()
          this.$swal({
            icon: 'success',
            title: 'Sessão finalizada!',
            text: 'A sessão foi finalizada com sucesso',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },

  },

}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
