import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    verificarPendencia(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.post('fut/afiliado/verificar-pendencia', queryParams)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    finalizar(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.post('fut/afiliado/finalizar', queryParams)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getSessaoUsuarios(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('fut/afiliado/sessao-usuario', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

  },
}
